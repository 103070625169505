<template>
    <div>
      <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

          <!-- Brand logo-->
          <b-link class="brand-logo" >
              <span >
                <b-img
                  :src="appLogoImageText"
                  alt="logo"
                  style="max-width: 180px"
                />
              </span>
          </b-link>
          <!-- /Brand logo-->

          <!-- Left Text-->
          <b-col
            lg="8"
            class="d-none d-lg-flex align-items-center p-5"
          >
            <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
              <imgLoginDark v-if="layout === 'dark'"/>
              <imgLogin v-else/>
            </div>
          </b-col>
          <!-- /Left Text-->

          <!-- Login-->
          <b-col
            lg="4"
            class="d-flex align-items-center auth-bg px-2 p-lg-5"
          >
            <b-col
              sm="8"
              md="6"
              lg="12"
              class="px-xl-2 mx-auto"
            >
              <b-card-title
                title-tag="h2"
                class="font-weight-bold mb-1"
              >
                Bem vindo à Troika! 👋
              </b-card-title>
              <b-card-text class="mb-2">
                Por favor, faça login na sua conta para começar a aventura
              </b-card-text>

              <!-- form -->
              <validation-observer ref="loginValidation">
                <b-form
                  class="auth-login-form mt-2"
                  @submit.prevent
                >
                  <!-- email -->
                  <b-form-group
                    label="E-mail"
                    label-for="login-email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      vid="email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="email"
                        v-model="userEmail"
                        :state="errors.length > 0 ? false:null"
                        name="email"
                        placeholder="john@example.com"
                        tabindex="1"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- forgot password -->
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="login-password">Senha</label>
                      <b-link :to="{name:'forgot', params: {prefix: this.prefix}}">
                        <small>Esqueceu sua senha?</small>
                      </b-link>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="login-password"
                          v-model="password"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="············"
                          tabindex="2"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- checkbox -->
                  <b-form-group>
                    <b-form-checkbox
                      id="remember-me"
                      v-model="status"
                      name="checkbox-1"
                    >
                      Lembrar-me
                    </b-form-checkbox>
                  </b-form-group>

                  <!-- submit buttons -->
                  <b-button
                    type="submit"
                    variant="primary"
                    block
                    @click="validationForm"
                  >
                    Entrar
                  </b-button>
                </b-form>
              </validation-observer>
            </b-col>
          </b-col>
        <!-- /Login-->
        </b-row>
      </div>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { $themeConfig } from '@themeConfig'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import imgLogin from '@/assets/images/pages/login-v2-troika.svg'
import imgLoginDark from '@/assets/images/pages/login-v2-dark.svg'
import { mapMutations } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    imgLogin,
    imgLoginDark
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      prefix: this.$router.currentRoute.params.prefix,
      overlay: false,
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    appLogoImageText(){
      const { appLogoImageText, appLogoImageTextDarkTheme } = $themeConfig.app
      return localStorage.getItem('vuexy-skin') === 'dark' ? appLogoImageTextDarkTheme: appLogoImageText
    },    
    layout(){
      return store.state.appConfig.layout.skin
    }
  },
  mounted() {
    this.UPDATE_FLAG_SHOW_OVERLAY(false);
  },
  methods: {
    ...mapMutations('app', [
      'UPDATE_FLAG_SHOW_OVERLAY'
    ]),
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        
        if (success) {
          this.UPDATE_FLAG_SHOW_OVERLAY(true);
          let tenant = this.userEmail.split('@')
          this.prefix = this.prefix ? this.prefix : tenant[1].split('.')[0]
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
              prefix: this.prefix,
            })
            .then(response => {
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
              const { userData } = response.data
              this.$ability.update(userData.ability)

              localStorage.setItem('userData', JSON.stringify(userData))
              localStorage.setItem('prefix', this.prefix)
              localStorage.setItem('accessToken', response.data.accessToken)
              localStorage.setItem('language', 'pt');

              if(localStorage.getItem('infoAccountsLogin')){
                if(!(JSON.parse(localStorage.getItem('infoAccountsLogin')).find(item => item.guid == response.data.userData.guid))){
                  var guidAtual = JSON.parse(localStorage.getItem('infoAccountsLogin'))
                  guidAtual.push({guid:response.data.userData.guid, tokenId: response.data.tokenId})
                  localStorage.setItem('infoAccountsLogin',JSON.stringify(guidAtual))
                }
              }
              else{
                localStorage.setItem('infoAccountsLogin',JSON.stringify([{guid: response.data.userData.guid, tokenId: response.data.tokenId}]))
              }
              
              
              const pcpAccess = JSON.parse(localStorage.getItem('userData')).ability.find( element => element.action == 'manage') || JSON.parse(localStorage.getItem('userData')).ability.find( element => element.subject == 'pcp')
               
              this.$http.get(`/api/${this.prefix}/tenant-site`)
                .then(response => {
                  localStorage.setItem('sites', JSON.stringify(response.data))
                  localStorage.setItem('currentSite', JSON.stringify(response.data[0]))
                  if(!pcpAccess){
                    this.$router.push({name: 'operator', params: {prefix: this.prefix}}) 
                  }else{
                    this.$router.push({name: 'home', params: {prefix: this.prefix}}) 
                  }
                  })
                .catch(error => this.showToast(error.response.data.message || "Error finding sites", 'EditIcon', '', 'danger'))  

              
            })
            .catch(error => {
              this.UPDATE_FLAG_SHOW_OVERLAY(false);

              if (error.response.status === 401)
                return this.$refs.loginValidation.setErrors(error.response.data)
              
              this.prefix = null
              this.showToast(error.response.data.message || "Error when logging in", 'EditIcon', '', 'danger')
            })
        }
      })
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          text: text,
          variant,
        },
      })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.dark-layout {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
}
</style>
